import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const permissionList = [
    { name: 'ADD NEW USER', key: 'ADD NEW USER' },
    { name: 'VIEW / EDIT USER', key: 'VIEW / EDIT USER' },
    { name: 'ADD NEW STOCK', key: 'ADD NEW STOCK' },
    { name: 'VIEW STOCK', key: 'VIEW STOCK' },
    { name: 'STOCK DETAILS', key: 'STOCK DETAILS' },
    { name: 'CREATE NEW SALE', key: 'CREATE NEW SALE' },
    { name: 'VIEW SALE SUMMARY', key: 'VIEW SALE SUMMARY' },
    { name: 'ACCOUNTS', key: 'ACCOUNTS' },
    // Add more permissions as needed
];

export default function ViewUser() {
    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        axios.get("https://b2e.b8a.mytemp.website/users/users")
            .then((res) => {
                const mappedData = res.data.map((data, index) => ({
                    id: data.user_id,
                    label: data.user_id,
                    value: data.user_id,
                    permissions: data.access  // Ensure permissions are set or default to an empty array
                }));
                setUserData(mappedData);
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
    }, []);

    const handleChange = (event, newValue) => {
        setSelectedUser(newValue);

        if (newValue) {
            setSelectedUserId(newValue.id);
            const selectedUserData = userData.find(data => data.label === newValue.label);
            if (selectedUserData) {
                setPermissions(selectedUserData.permissions.split(","));
            } else {
                setPermissions([]);
            }
        } else {
            setPermissions([]);
        }
    };

    const handleCheckboxChange = (event, permissionKey) => {
        if (event.target.checked) {
            setPermissions([...permissions, permissionKey]);
        } else {
            setPermissions(permissions.filter(permission => permission !== permissionKey));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!selectedUserId) {
            alert('No user selected ):');
            return;
        }

        axios.patch(`https://b2e.b8a.mytemp.website/users/users/${selectedUserId}`, {
            access: permissions.join(',')
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => {
            alert('User permissions updated successfully (:',);
            const updatedUserData = userData.map((user) => {
                if (user.id === selectedUserId) {
                    return {
                        ...user,
                        permissions: permissions.join(', ')
                    };
                }
                return user;
            });
            setUserData(updatedUserData);
        })
        .catch((error) => {
            alert('Error updating user permissions ):');
        });
    };

    const handleCancel = () => {
        setSelectedUser(null);
        setSelectedUserId('');
        setPermissions([]);
    };

    return (
        <div>
            <div className="text-dark">
                <h2 className="mb-1 font-weight-bold" style={{ textTransform: "uppercase" }}>View / Edit User!</h2>
                <p className="text-small mb-0">{formattedDate}</p>
            </div>

            <br />

            <div className="card">
                <div className="card-body">
                    <h4 className="card-title" style={{ textTransform: "uppercase" }}>Edit User!</h4>
                    <p className="card-description" style={{ textTransform: "uppercase" }}>Select User by name</p>
                    <form className="forms-sample" onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label" style={{ textTransform: "uppercase" }}>User Name :</label>
                            <div className="col-sm-9">
                                <Box id="lol">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="age-select"
                                            options={userData}
                                            getOptionLabel={(option) => option.label}
                                            value={selectedUser}
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField {...params} label="Client Name" />}
                                        />
                                    </FormControl>
                                </Box>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label" style={{ textTransform: "uppercase" }}>User Permissions :</label>
                            <div className="col-sm-9">
                                <input
                                    disabled={true}
                                    type="text"
                                    className="form-control"
                                    id="exampleInputPassword2"
                                    placeholder="User Permissions"
                                    value={permissions.join(', ')}
                                />
                            </div>
                        </div>

                        <h3 className="mb-1 font-weight" style={{ textTransform: "uppercase" }}>User Permissions!</h3>

                        <br />

                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>MODULE NAME</th>
                                    <th style={{ textAlign: "right" }}>PERMISSION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permissionList.map((permission) => (
                                    <tr key={permission.key}>
                                        <td>{permission.name}</td>
                                        <td style={{ textAlign: "right" }}>
                                            <input
                                                type="checkbox"
                                                checked={permissions.includes(permission.key)}
                                                onChange={(event) => handleCheckboxChange(event, permission.key)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <br />

                        <button type="submit" className="btn btn-primary mr-2">Submit</button>
                        <button type="button" className="btn btn-light" onClick={handleCancel}>Clear</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
