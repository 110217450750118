import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

export default function SaleSummary() {
  const [salesData, setSalesData] = useState([]);
  const [filteredSalesData, setFilteredSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    product: '',
    date: '',
    month: '',
    year: ''
  });

  const today = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get('https://api.dremerz.net/api/shawarmabillingsales');
        setSalesData(response.data);
        setFilteredSalesData(response.data);
        setProducts([...new Set(response.data.map(sale => sale.productName))]);
      } catch (error) {
        setError('Error fetching sales data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    let filteredData = salesData;

    if (filters.product) {
      filteredData = filteredData.filter(sale => sale.productName === filters.product);
    }
    if (filters.date) {
      filteredData = filteredData.filter(sale => sale.date.split(' ')[0] === filters.date);
    }
    if (filters.month) {
      filteredData = filteredData.filter(sale => new Date(sale.date).getMonth() + 1 === parseInt(filters.month));
    }
    if (filters.year) {
      filteredData = filteredData.filter(sale => new Date(sale.date).getFullYear() === parseInt(filters.year));
    }

    setFilteredSalesData(filteredData);
  };

  const exportToExcel = () => {
    const newSalesData = filteredSalesData.map((sale, index) => ({
      "S.NO": index + 1,
      "Date": sale.date,
      "Product Name": sale.productName,
      "Quantity": sale.quantity,
      "MRP": sale.mrp,
      "Total Cost": parseFloat(sale.totalCost || 0).toFixed(2),
      "Invoice Number": sale.invoiceNumber
    }));
    const worksheet = XLSX.utils.json_to_sheet(newSalesData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Data");
    XLSX.writeFile(workbook, `Sales_Data_${formattedDate}.xlsx`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.dremerz.net/api/shawarmabillingsales/${id}`);
      setSalesData(salesData.filter(sale => sale.id !== id));
      setFilteredSalesData(filteredSalesData.filter(sale => sale.id !== id));
      alert('Deleted successfully');
    } catch (error) {
      console.error('Error deleting sale:', error);
      alert('Error deleting sale');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="text-dark">
        <h2 className="mb-1 font-weight-bold" style={{ textTransform: "uppercase" }}>View Sales!</h2>
        <p className="text-small mb-0">{formattedDate}</p>
      </div>
      <br />
      <div className="card">
        <div className="card-body">
          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <h4 className="card-title" style={{ textTransform: "uppercase" }}>Sales Table</h4>
            <button className='btn btn-primary' onClick={exportToExcel}>Export to Excel</button>
          </div>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginBottom: '20px' }}>
            <select name="product" value={filters.product} onChange={handleFilterChange} className="form-control">
              <option value="">Select Product</option>
              {products.map((product, index) => (
                <option key={index} value={product}>{product}</option>
              ))}
            </select>
            <input type="date" name="date" value={filters.date} onChange={handleFilterChange} className="form-control" />
            <select name="month" value={filters.month} onChange={handleFilterChange} className="form-control">
              <option value="">Select Month</option>
              {[...Array(12)].map((_, index) => (
                <option key={index} value={index + 1}>{index + 1}</option>
              ))}
            </select>
            <select name="year" value={filters.year} onChange={handleFilterChange} className="form-control">
              <option value="">Select Year</option>
              {[...Array(5)].map((_, index) => (
                <option key={index} value={today.getFullYear() - index}>{today.getFullYear() - index}</option>
              ))}
            </select>
            <button onClick={applyFilters} className="btn btn-primary">Apply Filters</button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table id="order-listing" className="table">
                  <thead>
                    <tr>
                      <th># S.NO</th>
                      <th>Date</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>MRP</th>
                      <th>Customer Name</th>
                      <th>Customer Number</th>
                      <th>Total Cost</th>
                      <th>Invoice Number</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSalesData.slice().reverse().map((sale, index) => (
   
                      <tr key={sale.id}>
                        <td>{index + 1}</td>
                        <td>{sale.date}</td>
                        <td>{sale.productName}</td>
                        <td>{sale.quantity}</td>
                        <td>{sale.mrp}</td>
                        <td>{sale.customerName || '-'}</td>
                        <td>{sale.customerPhone || '-'}</td>
                        <td>{parseFloat(sale.totalCost || 0).toFixed(2)}</td>
                        <td>{sale.invoiceNumber}</td>
                        {/* <td style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                          <button 
                            onClick={() => handleDelete(sale.id)}
                            className="btn btn-danger">Delete</button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                  <tbody
                  style={{
                    fontWeight:"bold",
                    backgroundColor:"#f9f9f9"
                  }}
                  >
                    <tr>
                      <td colSpan="3">Total</td>
                      <td>{filteredSalesData.reduce((acc, sale) => acc + parseFloat(sale.quantity || 0), 0)}</td>
                      <td>{filteredSalesData.reduce((acc, sale) => acc + parseFloat(sale.mrp || 0), 0)}</td>
                      <td>{filteredSalesData.reduce((acc, sale) => acc + parseFloat(sale.totalCost || 0), 0).toFixed(2)}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
