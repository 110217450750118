import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function AddInventory() {

  const [formData, setFormData] = useState({
    itemName: '',
    quantity: '',
    acquisitionDate: new Date().toISOString().split('T')[0],
    cost: '',
    weight: '',
  });

  const [errors, setErrors] = useState({});
  const [inventoryItems, setInventoryItems] = useState([]);

  const today = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.toUpperCase() }); // Convert input to uppercase
  };

  const validateForm = () => {
    const newErrors = {};
    for (const key in formData) {
      if (!formData[key]) {
        newErrors[key] = 'This field is required';
      }
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post('https://api.dremerz.net/api/shawarmainventory', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Inventory item added successfully!', response.data);
      alert('Inventory item added successfully!');
      setFormData({
        itemName: '',
        quantity: '',
        acquisitionDate: new Date().toISOString().split('T')[0],
        cost: '',
        weight: '',
      });
      setErrors({});
    } catch (error) {
      console.log('There was an error adding the inventory item!', error);
      alert('Error adding inventory item. Please try again.');
    }
  };

  const handleCancel = () => {
    setFormData({
      itemName: '',
      quantity: '',
      acquisitionDate: new Date().toISOString().split('T')[0],
      cost: '',
      weight: '',
    });
    setErrors({});
  };

  useEffect(() => {
    axios.get('https://api.dremerz.net/api/shawarmainventory')
      .then((res) => {
        console.log('Inventory items:', res.data);
        const options = res.data.map((data) => ({
          label: data.itemName.toUpperCase(), // Convert item name to uppercase
          key: data.id
        }));

        const uniqueOptions = options.filter((option, index, self) =>
          index === self.findIndex((t) => (
            t.label === option.label
          ))
        );

        setInventoryItems(uniqueOptions);
      })
      .catch((error) => {
        console.error('Error fetching inventory items:', error);
      });
  }, []);

  const handleAutocompleteChange = (event, value) => {
    setFormData({ ...formData, itemName: value ? value.label : event.target.value.toUpperCase() });
  };

  const handleAutocompleteInputChange = (event, value) => {
    setFormData({ ...formData, itemName: value.toUpperCase() });
  };

  return (
    <div>
      <div className="text-dark">
        <h2 className="mb-1 font-weight-bold" style={{ textTransform: "uppercase" }}>Manage Inventory</h2>
        <p className="text-small mb-0">{formattedDate}</p>
      </div>
      <br />

      <div className="card">
        <div className="card-body">
          <h4 className="card-title" style={{ textTransform: "uppercase" }}>Enter Inventory Data</h4>
          <p className="card-description" style={{ textTransform: "uppercase" }}>Fill the below</p>
          <form className="forms-sample" onSubmit={handleSubmit}>
            <div className="form-group row">
              <label htmlFor="itemName" className="col-sm-3 col-form-label">
                ITEM NAME
              </label>
              <div className="col-sm-9">
                <Autocomplete
                  freeSolo
                  options={inventoryItems}
                  getOptionLabel={(option) => option.label}
                  onChange={handleAutocompleteChange}
                  onInputChange={handleAutocompleteInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={`form-control ${errors.itemName ? 'is-invalid' : ''}`}
                      id="itemName"
                      name="itemName"
                      value={formData.itemName}
                      onChange={handleChange}
                      placeholder="ITEM NAME"
                    />
                  )}
                />
                {errors.itemName && <div className="invalid-feedback">{errors.itemName}</div>}
              </div>
            </div>

            {[
              { label: 'QUANTITY', name: 'quantity' },
              { label: 'WEIGHT', name: 'weight' },
              { label: 'COST', name: 'cost' },
              { label: 'ACQUISITION DATE', name: 'acquisitionDate', type: 'date' },
            ].map(({ label, name, type = 'text' }) => (
              <div className="form-group row" key={name}>
                <label htmlFor={name} className="col-sm-3 col-form-label">
                  {label}
                </label>
                <div className="col-sm-9">
                  <input
                    type={type}
                    className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
                    id={name}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    placeholder={label}
                  />
                  {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
                </div>
              </div>
            ))}

            <button type="submit" className="btn btn-primary mr-2">
              Submit
            </button>
            <button type="button" className="btn btn-light" onClick={handleCancel}>
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
