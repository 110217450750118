import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function ViewInventory() {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');

  useEffect(() => {
    axios.get('https://api.dremerz.net/api/shawarmainventory')
      .then((res) => {
        setInventoryItems(res.data);
        setFilteredItems(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching inventory items:', error);
        setError('Error fetching inventory items. Please try again later.');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setFilteredItems(
      inventoryItems.filter(item => {
        const itemDate = new Date(item.acquisitionDate);
        const matchesSearchTerm = item.itemName.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDate = filterDate ? itemDate.toISOString().split('T')[0] === filterDate : true;
        const matchesMonth = filterMonth ? itemDate.getMonth() + 1 === parseInt(filterMonth) : true;
        const matchesYear = filterYear ? itemDate.getFullYear() === parseInt(filterYear) : true;
        return matchesSearchTerm && matchesDate && matchesMonth && matchesYear;
      })
    );
  }, [searchTerm, filterDate, filterMonth, filterYear, inventoryItems]);

  const totalCost = filteredItems.reduce((acc, item) => acc + parseFloat(item.cost), 0);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2 className="mb-1 font-weight-bold" style={{ textTransform: "uppercase" }}>Inventory Summary</h2>
<div style={{display:"flex",justifyContent:"center",alignItems:"center",padding:"10px",gap:"10px"}}>
      <input
        type="text"
        placeholder="Filter by item name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="form-control mb-3"
      />

      <input
        type="date"
        placeholder="Filter by date"
        value={filterDate}
        onChange={(e) => setFilterDate(e.target.value)}
        className="form-control mb-3"
      />

      <input
        type="number"
        placeholder="Filter by month (1-12)"
        value={filterMonth}
        onChange={(e) => setFilterMonth(e.target.value)}
        className="form-control mb-3"
      />

      <input
        type="number"
        placeholder="Filter by year"
        value={filterYear}
        onChange={(e) => setFilterYear(e.target.value)}
        className="form-control mb-3"
      />

      </div>

      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Weight</th>
              <th>Cost</th>
              <th>Acquisition Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map(item => (
              <tr key={item.id}>
                <td>{item.itemName.toUpperCase()}</td>
                <td>{item.quantity}</td>
                <td>{item.weight}</td>
                <td>{item.cost}</td>
                <td>{new Date(item.acquisitionDate).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        <h4>Total Cost: {totalCost.toFixed(2)}</h4>
      </div>
    </div>
  );
}
