import React from 'react';
import { Document, Page, Text, View, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

const Invoice = ({ bill }) => {
  console.log(bill);
  return (
    <Document>
      <Page size="A8">
        <View style={{ padding: 5,paddingRight:15 }}>
          <Text style={{ textAlign: 'center', fontSize: 12 }}>Shawarma Forest</Text>
          <View style={{ paddingTop: 2 }}></View>
          <Text style={{ textAlign: 'center', fontSize: 10 }}>
            No 438, G.N.G Road, Varadharajapuram, Ambattur, Chennai, 
          </Text>
          <Text style={{ textAlign: 'center', fontSize: 10 }}>Tamil Nadu 600053.</Text>
          <View style={{ paddingTop: 2 }}></View>
          <Text style={{ textAlign: 'center', fontSize: 10 }}>Contact Us: 9087677787, 9500521279</Text>
          <View style={{ paddingTop: 5 }}></View>

          <Text style={{ textAlign: 'center', fontSize: 10, color: 'black' }}>
             <Text style={{ color: 'black' }}>PAYMENT BILL</Text> 
          </Text>
          <View style={{ paddingTop: 3 }}></View>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 5 }}>
            <Text style={{ fontSize: 10 }}>Date: {bill?.items[0].date.split(" ")[0]}</Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 5 }}>
            <Text style={{ fontSize: 10 }}>Time: {bill?.items[0].date.split(" ")[1]}</Text>
          </View>
          <View style={{ paddingTop: 2 }}></View>

  
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 5 }}>
            <Text style={{ fontSize: 10 }}>Invoice No:</Text>
            <Text style={{ fontSize: 10 }}> {
              bill?.items[0].invoiceNumber
            }</Text>
          </View>
          <View style={{ paddingTop: 3 }}></View>

          <Text style={{ textAlign: 'center', fontSize: 10, color: 'black' }}>
            ----------------------------------------------------------------------------
          </Text>
          <View style={{ paddingTop: 1 }}></View>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 5 }}>
            <Text style={{ fontSize: 10, flex: 2 }}>Product Name</Text>
            <Text style={{ fontSize: 10, flex: 1, textAlign: 'center' }}>Price</Text>
            <Text style={{ fontSize: 10, flex: 1, textAlign: 'center' }}>Qty</Text>
            <Text style={{ fontSize: 10, flex: 1, textAlign: 'center' }}>Total</Text>
          </View>

          <Text style={{ textAlign: 'center', fontSize: 10, color: 'black' }}>
            ----------------------------------------------------------------------------
          </Text>
          <View style={{ paddingTop: 1 }}></View>

          {bill.items.map((item, index) => (
            <View key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 5, paddingTop: 2 }}>
              <Text style={{ fontSize: 9, flex: 2, overflow: 'break-word' }}>{item.productName}</Text>
              <Text style={{ fontSize: 10, flex: 1, textAlign: 'center' }}>{item.mrp}</Text>
              <Text style={{ fontSize: 10, flex: 1, textAlign: 'center' }}>{item.quantity}</Text>
              <Text style={{ fontSize: 10, flex: 1, textAlign: 'center' }}>{parseInt(item.mrp || 0) * parseInt(item.quantity || 0)}</Text>
            </View>
          ))}

          <View style={{ paddingTop: 2 }}></View>
          <Text style={{ textAlign: 'center', fontSize: 10, color: 'black' }}>
            ----------------------------------------------------------------------------
          </Text>
          <View style={{ paddingTop: 2 }}></View>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10 }}>
            <Text style={{ fontSize: 10 }}>Total:</Text>
            <Text style={{ fontSize: 10 }}>
              {bill.items.reduce((acc, item) => acc + parseInt(item.mrp || 0) * parseInt(item.quantity || 0), 0)}
            </Text>
          </View>
        </View>
        <Text style={{ textAlign: 'center', fontSize: 10, color: 'black' }}>
           <Text style={{ color: 'black' }}>THANK YOU ( :</Text> 
         
        </Text>
        <Text style={{ color: 'black',marginTop:2,fontSize: 10,textAlign:"center" }}>Visit Us Again</Text>
        <View style={{ paddingTop: 2 }}></View>
        <Text style={{ fontSize: 8, color: 'black', paddingHorizontal: 10 }}>
          This is a computer generated bill and does not require any signature or stamp.
        </Text>
      </Page>
    </Document>
  );
}

export default Invoice;
