import React from 'react'
import { Link } from 'react-router-dom'

export default function SideBar() {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
    <ul className="nav">
    {/* <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="collapse"
          href="#ui-basic2"
          aria-expanded="false"
          aria-controls="ui-basic2"
        >
          <i className="mdi mdi-account-edit menu-icon" />
          <span className="menu-title">USERS</span>
          <i className="menu-arrow" />
        </a>

        <div className="collapse" id="ui-basic2">
          <ul className="nav flex-column sub-menu">
            <li className="nav-item">
              {" "}
              <Link to={"/new-user"} className="nav-link">
                ADD NEW USER
              </Link>
            </li>

            <li className="nav-item">
              {" "}
              <Link to={"/view-users"} className="nav-link" >
                 VIEW / EDIT USER
              </Link>
            </li>
     
          </ul>
        </div>
      </li> */}
      <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="collapse"
          href="#ui-basic"
          aria-expanded="false"
          aria-controls="ui-basic"
        >
          <i className="mdi mdi-package menu-icon" />
          <span className="menu-title">PRODUCT</span>
          <i className="menu-arrow" />
        </a>

        <div className="collapse" id="ui-basic">
          <ul className="nav flex-column sub-menu">
            <li className="nav-item">
              {" "}
              <Link to={"/add-stock"} className="nav-link" >
                ADD PRODUCT
              </Link>
            </li>
            <li className="nav-item">
              {" "}
              <Link to={"/view-stock"} className="nav-link">
              PRODUCT DETAILS
              </Link>
            </li>
       
     
          </ul>
        </div>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="collapse"
          href="#ui-basic1"
          aria-expanded="false"
          aria-controls="ui-basic1"
        >
          <i className="mdi mdi-sale menu-icon" />
          <span className="menu-title">SALES</span>
          <i className="menu-arrow" />
        </a>

        <div className="collapse" id="ui-basic1">
          <ul className="nav flex-column sub-menu">
            <li className="nav-item">
              {" "}
              <Link to={"/new-sale"} className="nav-link">
                CREATE NEW SALE
              </Link>
            </li>

            <li className="nav-item">
              {" "}
              <Link to={"/sale-summary"}   className="nav-link" >
                 SALES SUMMARY
              </Link>
            </li>
     
          </ul>
        </div>
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="collapse"
          href="#ui-basic2"
          aria-expanded="false"
          aria-controls="ui-basic2"
        >
          <i className="mdi mdi-inbox menu-icon" />
          <span className="menu-title">INVENTORY</span>
          <i className="menu-arrow" />
        </a>

        <div className="collapse" id="ui-basic2">
          <ul className="nav flex-column sub-menu">
            <li className="nav-item">
              {" "}
              <Link to={"/add-inventory"} className="nav-link" >
                ADD INVENTORY
              </Link>
            </li>
            <li className="nav-item">
              {" "}
              <Link to={"/view-inventory"} className="nav-link">
              VIEW INVENTORY 
              </Link>
            </li>
       
     
          </ul>
        </div>
      </li>
    

      {/* <li className="nav-item">
        <a className="nav-link" >
          <i className="mdi mdi-currency-inr menu-icon" />
          <span className="menu-title">ACCOUNTS</span>
        </a>
      </li>
     */}


    </ul>
  </nav>
  
  )
}
