import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

// Define the styles for the PDF


// Define the Invoice component
const Invoice = ({ bill }) => (
  <Document>
    <Page size="A8" >
     <View style={{
        padding: 5,
     }}>
        <Text style={{textAlign:"center",fontSize:7}}>Shawarma Forest </Text>
        <View style={{paddingTop:2}}></View>
        <Text style={{textAlign:"center",fontSize:5}}> No 438, Shankar, Gng Road, Varadharajapuram, Ambattur, Chennai, Tamil Nadu 600053 </Text>
        <View style={{paddingTop:2}}></View>/
        <Text style={{textAlign:"center",fontSize:5}}>  Contact Us: 9087677787, 9500521279 </Text>
        <View style={{paddingTop:5}}></View>

        <Text style={{textAlign:"center",fontSize:5,color:"grey"}}>  -------------------------- <Text style={{color:"black"}}>PAYMENT  BILL</Text> -------------------------- </Text>


        <View style={{paddingTop:3}}></View>

       <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingHorizontal:5}}>
         <Text style={{fontSize:5}}>Date: {bill.date}</Text>
         <Text style={{fontSize:5}}>Time: {bill.time}</Text>
        </View>
        <View style={{paddingTop:2}}></View>

        <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingHorizontal:5}}>
         <Text style={{fontSize:5}}>Invoice No:</Text>
         <Text style={{fontSize:5}}> {bill.invoice}</Text>
        </View>
        <View style={{paddingTop:3}}></View>


        <Text style={{textAlign:"center",fontSize:5,color:"grey"}}>  ---------------------------------------------------------------------------- </Text>
        <View style={{paddingTop:1}}></View>

        <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingHorizontal:5}}>
            <View>
<Text style={{fontSize:5}}>Product Name</Text>
            </View>
            <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingHorizontal:5,gap:5}}>
            <Text style={{fontSize:5}}>Price</Text>
            <Text style={{fontSize:5}}>Qty</Text>
            <Text style={{fontSize:5}}>Total</Text>

            </View>

        </View>

        <Text style={{textAlign:"center",fontSize:5,color:"grey"}}>  ---------------------------------------------------------------------------- </Text>
        <View style={{paddingTop:3}}></View>

        <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingHorizontal:5}}>
            <View>
<Text style={{fontSize:4.5,
overflow:"break-word"
}}>Kango Tikka Shawarma Roll </Text>
            </View>
            <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingHorizontal:5,gap:5}}>
            <Text style={{fontSize:5}}>100</Text>
            <Text style={{fontSize:5}}>3</Text>
            <Text style={{fontSize:5}}>300</Text>

            </View>

        </View>
        <View style={{paddingTop:2}}></View>

        <Text style={{textAlign:"center",fontSize:5,color:"grey"}}>  ---------------------------------------------------------------------------- </Text>
        <View style={{paddingTop:2}}></View>

<View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingHorizontal:10}}>
    <Text style={{fontSize:0}}></Text>
    <Text style={{fontSize:5}}> Total: 300</Text>
    </View>



     </View>

    




     <Text style={{textAlign:"center",fontSize:5,color:"grey"}}>  -------------------------- <Text style={{color:"black"}}>THANK YOU</Text> -------------------------- </Text>
   
     <View style={{paddingTop:2}}></View>
   
     <Text style={{textAlign:"",fontSize:4,color:"grey",paddingHorizontal:10}}> 
     This is a computer generated bill and does not require any signature or stamp.
     </Text>
    </Page>
  </Document>
);

// Example bill data
const billData = {
    invoice: 'AL21387',
  date: '12/12/2023',
  time: '01:00',
  branch: 'Dhaka',
  receipt: 'AL21387',
  items: [
    { name: 'Beverages', price: '$10', qty: '3', total: '$10' },
    { name: 'Biscuit & Bakery', price: '$20', qty: '2', total: '$20' },
    { name: 'Culinary', price: '$40', qty: '3', total: '$40' },
    { name: 'Dairy', price: '$50', qty: '4', total: '$50' },
    { name: 'Noluisse', price: '$20', qty: '3', total: '$20' },
    { name: 'Dairy', price: '$70', qty: '1', total: '$70' },
    { name: 'Confectionery', price: '$58', qty: '2', total: '$58' },
    { name: 'Dairy', price: '$90', qty: '1', total: '$64' }
  ],
  subTotal: '$1010.00',
  discount: '-$106.00',
  serviceCharge: '0.00$',
  tax: '$47',
  totalBill: '$1257',
  due: '0$',
  cashPaid: '1400$',
  changeAmount: '985.00$',
};

// Main App component
const App = () => (
  <div>
    <PDFViewer width="100%" height="600">
      <Invoice bill={billData} />
    </PDFViewer>
    <PDFDownloadLink document={<Invoice bill={billData} />} fileName="invoice.pdf">
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
    </PDFDownloadLink>
  </div>
);

export default App;
