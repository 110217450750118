import React, { useState, useRef } from 'react';
import DremerzLogo from "../images/dremerz1.png";
import Dz from "../images/dz.png";

export default function NavBar() {

  const [isFullscreen, setIsFullscreen] = useState(false);


  const handleFullscreenClick = () => {
    if(!isFullscreen){
    var element = document.documentElement; // Get the root element (whole document)

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE/Edge */
      element.msRequestFullscreen();
    }
    setIsFullscreen(true)}
  };

 const  handleExitFullscreenClick = () => {
if(isFullscreen){
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
    setIsFullscreen(false)}
  };


  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      
    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-between" style={{
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    }}>

      {/* desktop */}
      <a className="navbar-brand brand-logo" href="index-2.html">
        <img
        style={{width:"100%"}}
          src={DremerzLogo}
          alt="logo"
        />
      </a>
      {/* mobile */}
      <a className="navbar-brand brand-logo-mini" href="index-2.html" >
        <img
         style={{minWidth:"42px",height:"38px",position:"fixed",left:"7px",top:"20px"}}
          src={Dz}
          alt="logo"
        />
      </a>

     
    </div>


    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
      <div className="d-none d-lg-flex">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-toggle="minimize"
        >
          <span className="mdi mdi-menu" />
        </button>
       
      </div>

      <ul className="navbar-nav navbar-nav-right">
      
        <li className="nav-item nav-profile dropdown">
          <a className="nav-link" href="#" data-toggle="dropdown" id="screenActionDropdown">
            <i className="mdi mdi-view-grid text-info mr-0" />
          </a>
          <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="screenActionDropdown">
            <a className="dropdown-item" onClick={handleFullscreenClick} >
              <i className="mdi mdi-fullscreen text-primary" />
              Fullscreen
            </a>
            <a className="dropdown-item" onClick={handleExitFullscreenClick} >
              <i className="mdi mdi-fullscreen-exit text-primary" />
              Exit Fullscreen
            </a>
          </div>
        </li>
        <li className="nav-item nav-profile dropdown">
          <div className="nav-link" data-toggle="dropdown" id="profileDropdown">
            <span>Hi, <span className="nav-profile-name">
              SHAWARMA FOREST
              </span></span>
            <span className="user-icon">SF</span>
          </div>
          <div className="dropdown-menu dropdown-menu-right navbar-dropdown user-profile-action" aria-labelledby="profileDropdown">
    
            <div className="p-3">
        
              <a className="dropdown-item py-1 px-2 d-flex align-items-center justify-content-between">
                <span>Lock Account</span>
                <i className="mdi mdi-lock ml-1" />
              </a>
              <a className="dropdown-item py-1  px-2 d-flex align-items-center justify-content-between"  >
                <span>Log Out</span>
                <i className="mdi mdi-logout ml-1" />
              </a>
            </div>
          </div>
        </li>
      </ul>
      <button
        className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
      >
        <span className="mdi mdi-menu" />
      </button>
    </div>
  </nav>
  
  )
}
