import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Bill from './Bill';
import { PDFViewer } from '@react-pdf/renderer';

export default function NewSale() {
  const formatDate = (e) => {
    const dateValue = new Date(e.target.value);
    const day = String(dateValue.getDate()).padStart(2, '0');
    const month = String(dateValue.getMonth() + 1).padStart(2, '0');
    const year = dateValue.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const today = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  const [date, setDate] = useState('');
  const [product, setProduct] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [pdfData, setPdfData] = useState(false);
  const [formData, setFormData] = useState({
    productName: '',
    mrp: '',
    quantity: 1,
    totalCost: 0,
    customerName: '',
    customerPhone: '',
    date: new Date().toISOString().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0],
  });

  const [overallData, setOverallData] = useState({
    invoiceNumber: Date.now(),
    paymentMethod1: '',
    amountPaid1: 0,
    paymentMethod2: '',
    amountPaid2: 0,
    balanceAmount: 0,
  });

  const handleChangeProduct = (event, newValue) => {
    setFilteredProduct(newValue);
    if (newValue) {
      setFormData({
        ...formData,
        productName: newValue.product_name,
        mrp: newValue.mrp,
        totalCost: newValue.mrp * formData.quantity,
        date: new Date().toISOString().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0],
      });
    }
  };

  const handleQuantityChange = (event) => {
    const quantity = event.target.value;
    setFormData({
      ...formData,
      quantity: quantity,
      totalCost: formData.mrp * quantity,
    });
  };

  const handleOverallDataChange = (event) => {
    const { name, value } = event.target;
    setOverallData({
      ...overallData,
      [name]: value,
      balanceAmount:
        dataList.reduce((acc, item) => acc + item.totalCost, 0) -
        (name === 'amountPaid1' ? parseFloat(value) : parseFloat(overallData.amountPaid1)) -
        (name === 'amountPaid2' ? parseFloat(value) : parseFloat(overallData.amountPaid2)),
    });
  };

  useEffect(() => {
    axios
      .get('https://api.dremerz.net/api/shawarmabillingproduct')
      .then((res) => {
        const uniqueProducts = res.data
          .map((item) => ({
            label: item.productName,
            value: item.productName,
            product_name: item.productName,
            mrp: item.mrp,
          }))
          .filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
        setProduct(uniqueProducts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    if (!formData.productName || !formData.mrp || !formData.quantity) {
      alert('Please fill all the fields');
      return;
    }
    event.preventDefault();
    const newFormData = {
      ...formData,
      
    };
    setDataList([...dataList, newFormData]);
    setFilteredProduct(null);
    setFormData({

      productName: '',
      mrp: '',
      quantity: 1,
      totalCost: 0,
      date: new Date().toISOString().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0],
    });
  };

  const loopPost = async () => {
    if (dataList.length === 0) {
      alert('Please add products to the list');
      return;
    }
    try {
      const postPromises = dataList.map((item) => {
        const data = {
          customerName: item.customerName,
          customerPhone: item.customerPhone,
          invoiceNumber: overallData.invoiceNumber,
          productName: item.productName,
          mrp: item.mrp,
          quantity: item.quantity,
          totalCost: item.totalCost,
          date: new Date().toISOString().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0],
          paymentMethod1: overallData.paymentMethod1,
          amountPaid1: overallData.amountPaid1,
          paymentMethod2: overallData.paymentMethod2,
          amountPaid2: overallData.amountPaid2,
          balanceAmount: overallData.balanceAmount,
        };

        return axios.post('https://api.dremerz.net/api/shawarmabillingsales', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      });

      await Promise.all(postPromises);

      alert('Sales created successfully (:');

      setPdfData(true);
      setOverallData({
        invoiceNumber: 'INV' + Date.now(),
        paymentMethod1: '',
        amountPaid1: 0,
        paymentMethod2: '',
        amountPaid2: 0,
        balanceAmount: 0,
        
      });

    } catch (error) {
      console.error(error);
      alert('Error creating sales ):');
    }
  };

  return (
    <>
      {pdfData ? (
        <PDFViewer width="100%" height="600">
          <Bill bill={{ ...overallData, items: dataList, totalBill: overallData.balanceAmount }} />
        </PDFViewer>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="text-dark">
              <h2 className="mb-1 font-weight-bold">Create New Sale!</h2>
              <p className="text-small mb-0">{formattedDate}</p>
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
            <Box id="lol" sx={{ minWidth: 250 }}>
              <FormControl fullWidth>
                <Autocomplete
                  id="product-select"
                  options={product}
                  getOptionLabel={(option) => option.label}
                  value={filteredProduct || null}
                  onChange={handleChangeProduct}
                  renderInput={(params) => <TextField {...params} label="Product" />}
                  freeSolo
                />
              </FormControl>
            </Box>
          </div>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>

                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="productName">Product Name</label>
                  <input type="text" className="form-control" id="productName" name="productName" disabled value={formData.productName} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '150px', textTransform: 'uppercase' }}>
                  <label htmlFor="mrp">MRP</label>
                  <input type="text" className="form-control" id="mrp" name="mrp" value={formData.mrp} disabled />
                </div>
                <div className="form-group" style={{ width: '100px', textTransform: 'uppercase' }}>
                  <label htmlFor="quantity">Quantity</label>
                  <input type="number" className="form-control" id="quantity" name="quantity" value={formData.quantity} onChange={handleQuantityChange} />
                </div>
                <div className="form-group" style={{ width: '150px', textTransform: 'uppercase' }}>
                  <label htmlFor="totalCost">Total Cost</label>
                  <input type="text" className="form-control" id="totalCost" name="totalCost" value={formData.totalCost.toFixed(2)} readOnly />
                </div>
              </div>
              <br />
              <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px' }}>
                <div className="form-group" style={{ width: '200px' }}>
                  <label htmlFor="customerName">Customer Name</label>
                  <input type="text" className="form-control" id="customerName" name="customerName" value={formData.customerName} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '200px' }}>
                  <label htmlFor="customerPhone">Customer Number</label>
                  <input type="text" className="form-control" id="customerPhone" name="customerPhone" value={formData.customerPhone} onChange={handleInputChange} />
                </div>
              </div>

              <div className="card-body" style={{ float: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                <button type="submit" className="btn btn-primary mr-2">Add to List</button>
              </div>
            </div>
          </form>
          <br />
          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>MRP</th>
                    <th>Quantity</th>
                    <th>Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.productName}</td>
                      <td>{item.mrp}</td>
                      <td>{item.quantity}</td>
                      <td>{item.totalCost.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '30px' }}>
                <div className="form-group" style={{ width: '200px' }}>
                  <label htmlFor="paymentMethod1">Payment Method 1</label>
                  <select className="form-control" id="paymentMethod1" name="paymentMethod1" value={overallData.paymentMethod1} onChange={handleOverallDataChange}>
                    <option value="">Select Payment Method</option>
                    <option value="UPI">UPI</option>
                    <option value="Card">Card</option>
                    <option value="Cash">Cash</option>
                  </select>
                </div>
                <div className="form-group" style={{ width: '200px' }}>
                  <label htmlFor="amountPaid1">Amount Paid 1</label>
                  <input type="number" className="form-control" id="amountPaid1" name="amountPaid1" value={overallData.amountPaid1} onChange={handleOverallDataChange} />
                </div>
                <div className="form-group" style={{ width: '200px' }}>
                  <label htmlFor="paymentMethod2">Payment Method 2</label>
                  <select className="form-control" id="paymentMethod2" name="paymentMethod2" value={overallData.paymentMethod2} onChange={handleOverallDataChange}>
                    <option value="">Select Payment Method</option>
                    <option value="UPI">UPI</option>
                    <option value="Card">Card</option>
                    <option value="Cash">Cash</option>
                  </select>
                </div>
                <div className="form-group" style={{ width: '200px' }}>
                  <label htmlFor="amountPaid2">Amount Paid 2</label>
                  <input type="number" className="form-control" id="amountPaid2" name="amountPaid2" value={overallData.amountPaid2} onChange={handleOverallDataChange} />
                </div>
                <div className="form-group" style={{ width: '200px' }}>
                  <label htmlFor="balanceAmount">Balance Amount</label>
                  <input type="text" className="form-control" id="balanceAmount" name="balanceAmount" value={overallData.balanceAmount.toFixed(2)} readOnly />
                </div>
              </div>
              <br />
              <div className="card-body" style={{ float: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                <button type="submit" onClick={loopPost} className="btn btn-primary mr-2">Complete Sale</button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
