import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';

export default function AddStock() {

  const [formData, setFormData] = useState({
    productName: '',
    mrp: '',
    date : new Date().toISOString().split('T')[0] + " " + new Date().toTimeString().split(' ')[0],
  });

  const [errors, setErrors] = useState({});
  const [stockNames, setStockNames] = useState([]);
  const [isProductNew, setIsProductNew] = useState(false);

  const today = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.toUpperCase() }); // Convert input to uppercase
  };

  const validateForm = () => {
    const newErrors = {};
    for (const key in formData) {
      if (!formData[key]) {
        newErrors[key] = 'This field is required';
      }
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post('https://api.dremerz.net/api/shawarmabillingproduct', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Stock added successfully!', response.data);
      alert('Stock added successfully!');
      setFormData({
        productName: '',
        mrp: '',
        date: new Date().toISOString().split('T')[0] + " " + new Date().toTimeString().split(' ')[0],
      });
      setErrors({});
    } catch (error) {
      console.log('There was an error adding the stock!', error);
      alert('Error adding stock. Please try again.');
    }
  };

  const handleCancel = () => {
    setFormData({
      productName: '',
      mrp: '',
      date: new Date().toISOString().split('T')[0] + " " + new Date().toTimeString().split(' ')[0],
    });
    setErrors({});
  };

  useEffect(() => {
    axios.get('https://api.dremerz.net/api/shawarmabillingproduct')
      .then((res) => {
        console.log('Stock names:', res.data);
        const options = res.data.map((data) => ({
          label: data.product_name.toUpperCase(), // Convert product name to uppercase
          key: data.id
        }));

        const uniqueOptions = options.filter((option, index, self) =>
          index === self.findIndex((t) => (
            t.label === option.label
          ))
        );

        setStockNames(uniqueOptions);
      })
      .catch((error) => {
        console.error('Error fetching stock names:', error);
      });
  }, []);

  const handleAutocompleteChange = (event, value) => {
    if (value) {
      setFormData({ ...formData, productName: value.label });
    } else {
      setFormData({ ...formData, productName: event.target.value.toUpperCase() }); // Convert input to uppercase
    }
  };

  const handleAutocompleteInputChange = (event, value) => {
    setFormData({ ...formData, productName: value.toUpperCase() }); // Convert autocomplete input to uppercase
  };

  return (
    <div>
      <div className="text-dark">
        <h2 className="mb-1 font-weight-bold" style={{ textTransform: "uppercase" }}>Add New Product!</h2>
        <p className="text-small mb-0">{formattedDate}</p>
      </div>
      <br />

      <div className="card">
        <div className="card-body">
          <h4 className="card-title" style={{ textTransform: "uppercase" }}>Enter Product Data!</h4>
          <p className="card-description" style={{ textTransform: "uppercase" }}>Fill the below</p>
          <form className="forms-sample" onSubmit={handleSubmit}>
            {[
              { label: 'PRODUCT NAME', name: 'productName' },
              { label: 'MRP', name: 'mrp' },
            ].map(({ label, name }) => (
              <div className="form-group row" key={name}>
                <label htmlFor={name} className="col-sm-3 col-form-label">
                  {label}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
                    id={name}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    placeholder={label}
                  />
                  {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
                </div>
              </div>
            ))}

            <button type="submit" className="btn btn-primary mr-2">
              Submit
            </button>
            <button type="button" className="btn btn-light" onClick={handleCancel}>
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
