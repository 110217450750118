import React from 'react'

export default function Footer() {
  return (
   
    <footer className="footer">
    <div className="d-sm-flex justify-content-center justify-content-sm-between">
      <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
        Copyright © 2024{" "}
        <a href="https://dremerz.com" target="_blank">
         Dremerz
        </a>
        . All rights reserved. Terms of use | Privacy Policy
      </span>
      <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
        Hand-crafted &amp; made with Love
      </span>
    </div>
  </footer>
  )
}
