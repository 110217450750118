import React, { useState } from 'react';
import axios from 'axios';

export default function NewUser() {
    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    const validate = () => {
        const errors = {};
        if (!username) errors.username = "Username is required";
        if (!password) errors.password = "Password is required";
        return errors;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const errors = validate();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        
        axios.post("https://b2e.b8a.mytemp.website/users/users", {
            userId: username,
            password: password,
            access:"user"
        },{headers: {
            'Content-Type': 'application/json',
          }})
        .then(() => {
            alert("User created successfully (:");
        })
        .catch((error) => {
            alert("Error creating user ): " );
        });
    }


    const clear =()=>{
        setPassword("")
        setUsername("")
       
    }

    return (
        <div>
            <div className="text-dark">
                <h2 className="mb-1 font-weight-bold" style={{textTransform:"uppercase"}}>Create New User!</h2>
                <p className="text-small mb-0">{formattedDate}</p>
            </div>

            <br />

            <div className="card">
                <div className="card-body">
                    <h4 className="card-title" style={{textTransform:"uppercase"}}>Add New User!</h4>
                    <p className="card-description" style={{textTransform:"uppercase"}}>Enter - User Name & Password</p>
                    <form className="forms-sample" onSubmit={onSubmit}>
                        <div className="form-group row">
                            <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">USER NAME :</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputUsername2"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                {errors.username && <div className="text-danger">{errors.username}</div>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">PASSWORD :</label>
                            <div className="col-sm-9">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="exampleInputPassword2"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {errors.password && <div className="text-danger">{errors.password}</div>}
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mr-2">Submit</button>
                        <button onClick={()=>{
                            clear()
                        }} type="button" className="btn btn-light">Clear</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
