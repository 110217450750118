import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import NotFound from './NotFound';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import SideBar from './components/SideBar';
import NewSale from './pages/NewSale';
import ViewUser from './pages/ViewUser';
import NewUser from './pages/NewUser';
import AddStock from './pages/AddStock';
import ViewStock from './pages/ViewStock';
import SaleSummary from './pages/SaleSummary';
import AddInventory from './pages/AddInventory';
import ViewInventory from './pages/ViewInventory';

export default function App() {
  return (

    <>
      <NavBar></NavBar>
      <div class="container-fluid page-body-wrapper">
        <SideBar/>
        <div class="main-panel">
          <div class="content-wrapper">
            <Routes>
              <Route path="/" >
                <Route index element={<NewSale />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/home" element={<Home />} />
                <Route path="/new-user" element={<NewUser />} />
                <Route path="/view-users" element={<ViewUser />} />
                <Route path="/add-stock" element={<AddStock />} />
                <Route path="/view-stock" element={<ViewStock />} />
                <Route path="/new-sale" element={<NewSale />} />
                <Route path="/sale-summary" element={<SaleSummary />} />

                <Route path="/add-inventory" element={<AddInventory />} />
                <Route path="/view-inventory" element={<ViewInventory />} />



              </Route>
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </>


  )
}
